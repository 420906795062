import React, { useState } from "react";
import ReactMap from "./lib/components/ReactMap";

import "./App.css";
import Collapsible from "react-collapsible";
import RunnerDetail from "./lib/components/runnerDetail";

function App() {
  const [steps, setSteps] = useState<any>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [remove_path, setremove_path] = useState(false);
  const [runnerDetailComp, setRunnerDetail] = useState<any>();
  const [showDirectionforAdmin, setshowDirectionforAdmin] = useState();
  function handleMapData(data: any) {
    setSteps(data?.steps);
    if (data === null) {
      setremove_path(false);
    }
    if (data?.steps) {
      setRunnerDetail(null);
      showDirection(null);
    }
  }
  const removePath = () => {
    setremove_path(true);
  };

  const handleRunnerDetail = (data: any) => {
    setRunnerDetail(
      <div className="runnerDetail">
        <RunnerDetail showDirection={showDirection} data={data} />
      </div>
    );
  };

  const showDirection = (param: any) => {
    setshowDirectionforAdmin(param);
  };

  return (
    <>
      <ReactMap
        onReceiveData={handleMapData}
        removePath={remove_path}
        onrecieveRunnerDetail={handleRunnerDetail}
        showDirectioAdmin={showDirectionforAdmin}
      />
      {runnerDetailComp}
      {steps && (
        <div onClick={() => setIsOpen(!isOpen)} className="infoBox">
          <Collapsible
            open={isOpen}
            handleTriggerClick={() => setIsOpen(!isOpen)}
            triggerStyle={{ color: "white", fontWeight: 800 }}
            trigger={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>Path Details</span>
                <span>{isOpen ? "▲" : "▼"}</span>
              </div>
            }
          >
            <div className="details-parts">
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "start",
                  marginTop: 10,
                }}
              >
                {!steps.from ? (
                  <>
                    <p className="inside-text">
                      {steps?.distance >= 1000
                        ? `${(Number(steps?.distance) / 1000).toFixed(2)} KM`
                        : `${Number(steps?.distance)} Meter`}
                    </p>
                    <p className="inside-text">{steps?.duration} Minutes</p>
                  </>
                ) : (
                  <>
                    <p className="inside-text">
                      {steps?.distance >= 1000
                        ? `${(Number(steps?.distance) / 1000).toFixed(2)} KM`
                        : `${Number(steps?.distance).toFixed(0)} Meter`}
                    </p>
                    <p className="inside-text">
                      {(Number(steps?.duration) / 60).toFixed(0)} Minutes
                    </p>
                  </>
                )}
              </div>
            </div>
            {/* {steps?.legs[0].steps.map((step: any, index: any) => (
              <div key={index} className="directionStep">
                <small className="inside-text">
                  {Math.round(step.distance)} meters,{" "}
                  {Math.round(step.duration)} seconds
                </small>
              </div>
            ))} */}
          </Collapsible>
        </div>
      )}

      {steps && (
        <button className="remove_path" onClick={removePath}>
          X
        </button>
      )}
    </>
  );
}

export default App;
export { ReactMap };
