import React, { useEffect, useState, useRef } from "react";
import { Marker, Source, Layer, Popup } from "react-map-gl";
import { DateTime } from "luxon";

import CustomOverlay from "../../map/customOverlay";
import { OrderStatusEnum } from "../constants/orderStatusEnum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPersonWalking,
} from "@fortawesome/free-solid-svg-icons";
import io from "socket.io-client";

import "./styles.css";

const mapLayer: any = {
  id: "route",
  type: "line",
  source: "route",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "orange",
    "line-width": 5,
  },
};
interface GetOrdersParams {
  date_from: string; // or Date, if applicable
  date_to: string; // or Date, if applicable
  accessToken: string;
}

const OrderIcons = (
  <FontAwesomeIcon icon={faLocationDot} bounce style={{ fontSize: 35 }} />
);

const RunnerIcons = (
  <FontAwesomeIcon icon={faPersonWalking} bounce style={{ fontSize: 25 }} />
);
const StartLocationIcon = (
  <FontAwesomeIcon
    icon={faLocationDot}
    style={{ fontSize: 50, color: "orange" }}
  />
);
const DeliveryIcon = (
  <FontAwesomeIcon
    icon={faLocationDot}
    beat
    style={{ color: "#85d016", fontSize: 50 }}
  />
);
function MapAdmin(props: any) {
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [Orders, setOrders] = useState([]);
  const [runnerOrders, setRunnerOrders] = useState([]);

  const [Runners, setRunners] = useState<any[]>([]);
  const [tokenInvalid, setTokenIvalid] = useState(false);
  const today = DateTime.local().toFormat("yyyy-MM-dd");

  const RunnersRef = useRef(Runners);

  useEffect(() => {
    setOrders([]);
    if (!navigator.geolocation) {
      alert("Turn on Your location");
      return;
    }

    navigator?.permissions?.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        getLocation();
      }
    });

    function getLocation() {
      console.log("ciao");
    }
  }, []);

  useEffect(() => {
    RunnersRef.current = Runners;
  }, [Runners]);

  useEffect(() => {
    setTokenIvalid(false);
    getRunners({
      accessToken: props.access_token,
    });
    getOrders({
      date_from: today,
      date_to: today,
      accessToken: props.access_token,
    });
  }, [props.refresh]);

  async function getRunners({ accessToken }: { accessToken: string }) {
    if (accessToken && !tokenInvalid) {
      const urlParams = new URLSearchParams({
        created_at: DateTime.local().toFormat("yyyy-MM-dd"),
      });
      const url = `${
        process.env.REACT_APP_API
      }/runner-position/?${urlParams.toString()}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        console.log("Network response was not ok " + response.statusText);
        if (response.status == 401) {
          setTokenIvalid(true);
        }
      } else {
        const responseData = await response.json();
        console.log("update runners");

        setRunners(responseData.data);
      }
    }
  }

  async function getOrders({
    date_from,
    date_to,
    accessToken,
  }: GetOrdersParams) {
    if (accessToken && !tokenInvalid) {
      const urlParams = new URLSearchParams({ date_from, date_to });
      const url = `${
        process.env.REACT_APP_API
      }/food/order/?${urlParams.toString()}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        // throw new Error("Network response was not ok " + response.statusText);
        console.log("Network response was not ok " + response.statusText);
        if (response.status == 401) {
          setTokenIvalid(true);
        }
      } else {
        const responseData = await response.json();
        setRunnerOrders(responseData.data);
      }
    }
  }

  const isRunnerActive = (runner: any) => {
    const start = new Date();
    start.setMinutes(start.getMinutes() - 30);
    const end = new Date();
    end.setMinutes(end.getMinutes() + 30);

    const realTime = `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;
    const startTime = `${start.getHours()}:${start.getMinutes()}:${start.getSeconds()}`;
    const endTime = `${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`;

    const active = runner?.users?.runner_days?.find((rd: any) => {
      const start_range =
        rd?.saturation_days?.range_hour?.start_range?.split(/T|\./)[1];
      const end_range =
        rd?.saturation_days?.range_hour?.end_range?.split(/T|\./)[1];
      // console.log(start_range, end_range);
      // console.log(startTime, endTime);
      // console.log(
      //   `${start_range} <= ${startTime} && ${end_range} >= ${endTime}, ${realTime}`
      // );
      return (
        (start_range <= startTime || start_range <= realTime) &&
        (end_range >= endTime || end_range >= realTime)
      );
    });
    return active || runner?.users?.runner_orders?.length;
  };

  useEffect(() => {
    if (props.showDirectioAdmin) {
      const order = props.showDirectioAdmin;
      let path_to_send = {
        lat: JSON.stringify(order.store.latitude),
        lng: JSON.stringify(order.store.longitude),
        lat1: JSON.stringify(order.food_orders)
          ? JSON.stringify(order.food_orders.latitude)
          : JSON.stringify(order.smart_logistics_orders)
          ? JSON.stringify(order.food_orders.smart_logistics_orders.latitude)
          : null,
        lng1: JSON.stringify(order.food_orders)
          ? JSON.stringify(order.food_orders.longitude)
          : JSON.stringify(order.smart_logistics_orders)
          ? JSON.stringify(order.food_orders.smart_logistics_orders.longitude)
          : null,
        steps: {
          duration: Number(JSON.stringify(order.runner_walking_time)),
          distance: JSON.stringify(order.distance),
        },
      };
      props.showpath(path_to_send);

      props.onReceiveData({
        steps: {
          duration: path_to_send.steps.duration,
          distance: path_to_send.steps.distance,
        },
      });
    }
  }, [props.showDirectioAdmin]);

  useEffect(() => {
    // Connect to Socket.io server
    const socket = io(`${process.env.REACT_APP_URL}`, {
      path: "/socket.io",
      transports: ["websocket", "polling"],
      secure: true,
    });
    if (props.access_token) {
      // Listen for runner position updates
      socket.on("runnerposition-update", async (updatedPosition: any[]) => {
        if (updatedPosition && updatedPosition[0]) {
          const data: any[] = [...RunnersRef.current];
          const foundIndex = data.findIndex(
            (r: any) => r.runner_id == updatedPosition[0].runner_id
          );
          if (foundIndex != -1) {
            data[foundIndex] = updatedPosition[0];
          } else {
            data.push(updatedPosition[0]);
          }
          setRunners([...data]);
        } else {
          await getRunners({
            accessToken: props.access_token,
          });
        }
        console.log("Runner position updated:", updatedPosition);
      });
    }
    // Clean up on unmount
    return () => {
      socket.disconnect();
    };
  }, [props.access_token]);

  return (
    <div>
      <CustomOverlay>
        <div>Qui le opzioni</div>
      </CustomOverlay>
      {Orders.filter(
        (order: any) => order.status.name === OrderStatusEnum.NUOVO
      ).map((order: any, index: number) => (
        <Marker
          key={`marker-${index}`}
          longitude={order.store.longitude}
          latitude={order.store.latitude}
          anchor="bottom"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            // setPopupInfo(order);
          }}
        >
          {OrderIcons}
        </Marker>
      ))}
      {Runners.filter(isRunnerActive).map((runner: any, index: number) => (
        <Marker
          key={`marker-${index}`}
          longitude={runner.longitude}
          latitude={runner.latitude}
          anchor="bottom"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            setPopupInfo({
              type: "runner",
              data: runner,
              runner_order: runnerOrders,
              accessToken: props.access_token,
            });
          }}
        >
          {RunnerIcons}
        </Marker>
      ))}

      {props.steps &&
        props.steps?.legs[0]?.steps &&
        props.steps?.legs[0]?.steps?.map((step: any, index: any) => (
          <Marker
            key={`step-${index}`}
            longitude={step.maneuver.location[0]}
            latitude={step.maneuver.location[1]}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();

              // setPopupInfoPath(step);
            }}
          >
            {index === 0 ? (
              StartLocationIcon
            ) : index === props.steps.legs[0].steps.length - 1 ? (
              DeliveryIcon
            ) : (
              <i
                className="fa fa-map-marker"
                title="Step"
                style={{ color: "red", fontSize: "1em", display: "none" }}
              ></i>
            )}
          </Marker>
        ))}
      {props.route && (
        <Source type="geojson" data={props.route}>
          <Layer {...mapLayer} />
        </Source>
      )}
      {popupInfo && popupInfo.type == "runner" && (
        <Popup
          anchor="top"
          longitude={Number(popupInfo.data.longitude)}
          latitude={Number(popupInfo.data.latitude)}
          onClose={() => setPopupInfo(null)}
          className="custom-popup"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <span className="popup_address_text">
                {popupInfo.data.users.fullname}
                <button
                  style={{
                    display: "block",
                    marginTop: "10px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "rgb(80, 246, 255)",
                    borderRadius: "16px",
                    border: "none",
                    padding: "5px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    //Mostrare percorso ordine
                    //Possibilita di chiudere modale detaglio runner
                    props.onrecieveRunnerDetail(popupInfo);
                  }}
                >
                  Detail
                </button>
              </span>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default MapAdmin;
