import React, { useState, useEffect } from "react";

const pinStyle = {
  cursor: "pointer",
  fill: "#d00",
  stroke: "none",
};

function CustomPin({
  size = 20,
  imgPath = "https://www.cocaiexpress.com/webapp/images/icons/food/pizza.png",
}) {
  const [img, setImg] = useState<string>("");
  useEffect(() => {
    mergeImages([
      { src: "./images/markers/_.png" },
      { src: imgPath, scale: 0.5, x: 130, y: 70 },
    ])
      .then((b64: any) => setImg(b64))
      .catch((_: any) => {
        console.log(imgPath);
      });
  }, []);

  return (
    <img
      src={img ? img : "./images/markers/_.png"}
      height={size}
      style={pinStyle}
    />
  );
}

export default React.memo(CustomPin);

const defaultOptions = {
  format: "image/png",
  quality: 0.92,
  width: undefined,
  height: undefined,
  Canvas: undefined,
  crossOrigin: undefined,
};

// Return Promise
const mergeImages = (sources: any[] = [], options: any = {}) =>
  new Promise((resolve) => {
    options = Object.assign({}, defaultOptions, options);

    // Setup browser/Node.js specific variables
    const canvas = options.Canvas
      ? new options.Canvas()
      : window.document.createElement("canvas");
    const Image = options.Image || window.Image;

    // Load sources
    const images = sources.map(
      (source) =>
        new Promise((resolve, reject) => {
          // Convert sources to objects
          if (source.constructor.name !== "Object") {
            source = { src: source };
          }

          // Resolve source and img when loaded
          const img = new Image();
          img.crossOrigin = options.crossOrigin;
          img.onerror = () => reject(new Error("Couldn't load image"));
          img.onload = () => resolve(Object.assign({}, source, { img }));
          img.src = source.src;
        })
    );

    // Get canvas context
    const ctx = canvas.getContext("2d");

    // When sources have loaded
    resolve(
      Promise.all(images).then((images: any) => {
        // Set canvas dimensions
        const getSize = (dim: any) =>
          options[dim] ||
          Math.max(...images.map((image: any) => image.img[dim]));
        canvas.width = getSize("width");
        canvas.height = getSize("height");

        // Draw images to canvas
        images.forEach((image: any) => {
          ctx.globalAlpha = image.opacity ? image.opacity : 1;
          return ctx.drawImage(
            image.img,
            image.x || 0,
            image.y || 0,
            canvas.width * (image.scale || 1),
            canvas.height * (image.scale || 1)
          );
        });

        if (options.Canvas && options.format === "image/jpeg") {
          // Resolve data URI for node-canvas jpeg async
          return new Promise((resolve, reject) => {
            canvas.toDataURL(
              options.format,
              {
                quality: options.quality,
                progressive: false,
              },
              (err: any, jpeg: any) => {
                if (err) {
                  reject(err);
                  return;
                }
                resolve(jpeg);
              }
            );
          });
        }

        // Resolve all other data URIs sync
        return canvas.toDataURL(options.format, options.quality);
      })
    );
  });
