import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Marker } from "react-map-gl";

const OrderIcons = (
  <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: 35 }} />
);

function MapStore(props: any) {
  return (
    <div>
      {props.markers?.latitude && props.markers?.longitude && (
        <Marker
          longitude={props.markers?.longitude}
          latitude={props.markers?.latitude}
          anchor="bottom"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
          }}
        >
          {OrderIcons}
        </Marker>
      )}
    </div>
  );
}

export default MapStore;
