import React, { useEffect, useState } from "react";
import "./styles.css";
import WorkHourIndicator from "./statusIndicator/statusIndicator";
import OrderBox from "./orderBox/orderbox";
import axios from "axios";
import { DateTime } from "luxon";

function RunnerDetail(props: any) {
  const [runnerOrders, setOrders] = useState<any>([]);
  const [workingHour, setWorkingHour] = useState<any>();
  const [registrationDate, setResgistrationDate] = useState<any>();
  const [totalOrders, setTotalOrders] = useState<any>(0);
  const [remainingSlots, setRemainingSlots] = useState<any>([]);
  const [image_url, setImage_url] = useState<any>();
  useEffect(() => {
    setImage_url(
      `${process.env.REACT_APP_API}/media/upload/runnerProfile/${props.data?.data?.users.id}`
    );
  }, [props]);

  const [imageExists, setIMageExists] = useState(false);

  const checkImageExists = async (url: RequestInfo | URL) => {
    try {
      const response = await fetch(url, { method: "HEAD" });
      return response.ok;
    } catch (error) {
      console.error("Error checking image:", error);
      return false;
    }
  };

  useEffect(() => {
    if (props.data?.data?.users.id) {
      console.log(imageExists);

      checkImageExists(image_url).then((exists) => {
        if (exists) {
          setIMageExists(true);

          console.log(`Image exists:${image_url}`);
        } else {
          setIMageExists(false);
          console.log("Image does not exist.");
        }
      });
    }
  }, [image_url]);

  useEffect(() => {
    if (props.data.runner_order.length > 0) {
      setOrders(
        props.data.runner_order.filter((ord: any) => {
          return ord.runner_orders[0]?.user_id === props.data?.data?.users.id;
        })
      );
    }
  }, [props]);

  const encodedMessage = encodeURIComponent(
    `Hello ${props.data?.data?.users.fullname}`
  );
  const whatsappMessageLink = `https://wa.me/${props.data?.data?.users.phone}?text=${encodedMessage}`;
  const today = new Date();
  const monthLimitDay = DateTime.fromJSDate(today).plus({ day: 1 }).toJSDate();

  useEffect(() => {
    const regDate = props.data.data.users?.createdDate?.split("T")[0];
    const accessToken = props.data.accessToken;
    const targetRunnerId = String(props.data.data.runner_id);

    setResgistrationDate(regDate);

    const fetchRunnerOrders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/food/runner/orders`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              user_id: Number(targetRunnerId),
            },
          }
        );
        setTotalOrders(response.data.data.length);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchData = async () => {
      // Replace with your actual token
      // const parameterValue = props.data.data.runner_id; // Replace with your actual parameter value
      const targetRunnerId = String(props.data.data.runner_id);
      const id_runner_role = props.data.data.users.id_runner_role;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/food/saturations/get_working_hours`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: {
              id_runner_role: Number(id_runner_role),
              date_from: DateTime.fromJSDate(today).toFormat("yyyy-MM-dd"),
              date_to:
                DateTime.fromJSDate(monthLimitDay).toFormat("yyyy-MM-dd"),
            },
          }
        );

        const hoursWithRunners: any = response.data.data
          .flatMap((item: any) => item.hours) // Flattens hours arrays from each day
          .filter(
            (hour: any) =>
              hour.runners &&
              hour.runners.some(
                (runner: any) => runner.id_runner === targetRunnerId
              )
          );

        let tempRemainingSlots = [];
        if (hoursWithRunners.length > 0) {
          // Extract the date from the 'created_at' field of the first runner
          // const createdDate =
          //   hoursWithRunners[0].runners[0].created_at.split("T")[0];

          // Initialize start and end times
          let startTime = hoursWithRunners[0].start;
          let endTime = hoursWithRunners[0].end;

          for (let i = 1; i < hoursWithRunners.length; i++) {
            let currentStart = hoursWithRunners[i].start;
            let currentEnd = hoursWithRunners[i].end;

            if (new Date(currentStart) <= new Date(endTime)) {
              // Extend the end time if current start is before or equal to current end
              endTime =
                new Date(currentEnd) > new Date(endTime) ? currentEnd : endTime;
            } else {
              // For a gap, check if current start is closer to now
              let now = new Date();

              if (
                Math.abs(new Date(currentStart).getTime() - now.getTime()) >
                Math.abs(new Date(startTime).getTime() - now.getTime())
              ) {
                startTime = currentStart;
                endTime = currentEnd;
              } else {
                tempRemainingSlots.push({
                  start: `${DateTime.fromJSDate(today).toFormat(
                    "yyyy-MM-dd"
                  )}T${currentStart.split("T")[1]}`,
                  end: `${DateTime.fromJSDate(today).toFormat("yyyy-MM-dd")}T${
                    currentEnd.split("T")[1]
                  }`,
                });
              }
            }
          }

          const sortedSlots = [...tempRemainingSlots].sort(
            (a: any, b: any) =>
              new Date(a.start).getHours() - new Date(b.start).getHours()
          );
          setRemainingSlots(sortedSlots);
          setWorkingHour({
            start: `${DateTime.fromJSDate(today).toFormat("yyyy-MM-dd")}T${
              startTime.split("T")[1]
            }`,
            end: `${DateTime.fromJSDate(today).toFormat("yyyy-MM-dd")}T${
              endTime.split("T")[1]
            }`,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error); // Handle any errors
      }
    };
    if (props.data?.data?.runner_id) {
      fetchData();
      fetchRunnerOrders();
    }
  }, [props]);

  const getDateWithoutTimeZone = (time: string | Date) => {
    if (time instanceof Date) {
      time = time.toLocaleString().replace(".000Z", "");
    }
    return new Date(new Date(time.replace(".000Z", "")).getTime());
  };

  const RunnerType = (role: Number): string => {
    let runnerType = "";

    switch (role) {
      case 1:
        runnerType = "Delivery";
        break;
      case 2:
        runnerType = "Pro";
        break;
      case 3:
        runnerType = "Trial";
        break;
      case 4:
        runnerType = "Junior";
        break;
      case 5:
        runnerType = "Senior";
        break;

      default:
        break;
    }

    return runnerType;
  };

  return (
    <div className="card">
      <div className="top-section">
        <div className="border"></div>
        <div className="icons">
          <span style={{ display: "flex", color: "white", padding: "5px" }}>
            # {props.data?.data?.users.id}
          </span>
          <div className="social-media">
            <a
              href={whatsappMessageLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="50px"
                height="50px"
              >
                <path d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z" />
              </svg>
            </a>
            {/* <svg
              className="svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
            </svg>
            <svg
              className="svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
            </svg> */}
          </div>
        </div>{" "}
        <div className="container">
          <div className="profile-image">
            {/* Assuming you have an image called 'profile.jpg' in your public folder */}
            {!imageExists ? (
              <img src={`${process.env.PUBLIC_URL}/user.png`} alt="Profile" />
            ) : (
              <img
                style={{ borderRadius: "20px" }}
                src={image_url}
                alt="Description of the image"
              />
            )}
          </div>
          <div className="info">
            <div
              className="info-section"
              style={{
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {props.data?.data?.users.fullname}
            </div>
            <div className="info-section">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                <span> Registration Date</span>
                <span>{registrationDate} </span>
              </div>
            </div>

            <div className="info-section">
              <a
                style={{ color: "black", textDecoration: "none" }}
                href={`tel:${props.data?.data?.users.phone}`}
              >
                {props.data?.data?.users.phone}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <span className="title"> {props.data?.data?.users.fullname}</span>
        <div className="row row1">
          <div className="item">
            <span className="big-text">{totalOrders}</span>
            <span className="regular-text">Total orders</span>
          </div>
          <div className="item">
            <span className="big-text">
              {RunnerType(Number(props.data.data.users.id_runner_role))}
            </span>
            <span className="regular-text">Type of runner</span>
          </div>
          {/* <div className="item">
            <span className="big-text">38,631</span>
            <span className="regular-text">Contributers</span>
          </div> */}
        </div>
        {workingHour && (
          <div className="statusIndicator">
            <WorkHourIndicator
              startTime={new Date(getDateWithoutTimeZone(workingHour.start))}
              endTime={new Date(getDateWithoutTimeZone(workingHour.end))}
              isCurrent={true}
            />

            {remainingSlots && (
              <div>
                <h4 className="remainin-title">Also booked</h4>
                {remainingSlots.map((slot: any, index: any) => (
                  <div className="other-slots" key={index}>
                    <WorkHourIndicator
                      startTime={new Date(getDateWithoutTimeZone(slot.start))}
                      endTime={new Date(getDateWithoutTimeZone(slot.end))}
                      isCurrent={false}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        <span className="order_text"> Orders </span>
        <div className="orders_list">
          {runnerOrders.length > 0 ? (
            runnerOrders.map((order: any) => {
              return (
                <OrderBox
                  key={order.id}
                  order={order}
                  showDirection={() => {
                    console.log(props.showDirection());
                    props.showDirection(order);
                  }}
                  // totalUserOrders={fetchRunnerOrders(order.users.id)}
                  access_token={props.data.accessToken}
                />
              );
            })
          ) : (
            <span className="order_text_no_orders">
              No orders at the moment
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default RunnerDetail;
