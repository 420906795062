import React, { useState, useEffect, useRef } from "react";
import Map, {
  Marker,
  Popup,
  Source,
  Layer,
  GeolocateControl,
} from "react-map-gl";
import GeocoderControl from "../../map/geocoder-control";
import Pin from "../../map/pin";
import CustomPin from "../../map/custom-pin";
import mapConstants from "../constants/mapConstants";
import {
  IMapProps,
  IAppPayload,
  MapEventType,
  EMapType,
  UserType,
} from "../interfaces";
import MapRunner from "./mapRunner";
import MapAdmin from "./mapAdmin";
import MapStore from "./mapStores";

declare global {
  interface WindowEventMap {
    [MapEventType]: { detail: IAppPayload };
  }
}
// export const MapEventType = MapEventType;

const ReactMap: React.FC<IMapProps> = ({
  onReceiveData,
  removePath,
  onrecieveRunnerDetail,
  showDirectioAdmin,
}) => {
  const [forWho, setForWho] = useState("");
  const [access_token, setAccess_token] = useState("");
  const [stores, setStores] = useState<any[]>([]);
  const [markers, setMarkers] = useState<any[]>([]);
  const [markersStore, setMarkersStore] = useState<any>();

  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [refresh, setRefresh] = useState(false);
  const [pathPoints, setPathPoints] = useState<any>(null);
  const [route, setRoute] = useState(null);
  const [steps, setSteps] = useState<any>(null);
  // const [pathDetail, setPathDetail] = useState<any>();
  const [viewport, setViewport] = useState({
    longitude: 12.32609,
    latitude: 45.43708,
    zoom: 12.5,
    bearing: 0,
    pitch: 0,
  });
  const [data /*setData*/] = useState<any>({
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [],
    },
  });

  const categories = [
    "asiatico",
    "burgers",
    "gastronomia",
    "mediterraneo",
    "sushi",
    "poke",
    "pizza",
    "carne",
    "dolciegelati",
    "cocktailkit",
    "birra",
    "vino",
    "liquoriedistillati",
    "cocktail",
    "softdrinks",
    "pescheria",
    "ortofrutta",
    "prodotti_caseari",
    "panificati",
    "aromieprofumi",
    "macelleria",
    "salumieaffettati",
    "artigianato",
    "bodycare",
    "fiori",
    "petcare",
    "homebeauty",
    "utilities",
  ];

  // handle for who to show the map based on param
  const url = new URL(window.location.href);

  const typeParam = url.searchParams.get("type");
  useEffect(() => {
    if (typeParam === UserType.runner) {
      setForWho(UserType.runner);
    } else if (typeParam === UserType.admin) {
      setForWho(UserType.admin);
    } else if (typeParam === UserType.store) {
      setForWho(UserType.store);
    } else {
      console.log("no user type");
    }
  }, []);

  useEffect(() => {
    if (typeParam !== UserType.store) {
      fetch(`${process.env.REACT_APP_API}/food/store`)
        .then((res) => res.json())
        .then((res) => {
          setStores(
            res.data.filter((d: any) => checkLimits(d.latitude, d.longitude))
          );
        });
    }
    const appHandler: (messageData: {
      detail: IAppPayload;
    }) => void = (messageData: { detail: IAppPayload }) => {
      stores.length = 1;
      const data = messageData.detail;

      // if (data.stepByStep !== undefined) {
      //   setStepByStep(data.stepByStep);
      // } else if (data.type === EMapType.clearMap) {
      //   setStepByStep(false);
      // }
      switch (data.type) {
        case EMapType.toLocation:
          break;
        case EMapType.filters:
          break;
        case EMapType.setPoints:
          break;
        case EMapType.removePoints:
          break;
        case EMapType.resetPoints:
          break;
        case EMapType.setPath:
          break;
        case EMapType.setStepByStep:
          break;
        case EMapType.clearMap:
          break;
        case EMapType.for_runner:
          break;
      }
    };
    window.addEventListener(MapEventType, appHandler);
    return () => {
      window.removeEventListener(MapEventType, appHandler);
    };
  }, []);

  //Below i added new listner since the above code is not triggred

  if (window.addEventListener) {
    window.addEventListener("message", function (event) {
      // Here, event.data contains the data sent from React Native.
      // console.log(event.data.type);
      // setStores([]);
      switch (event.data.type) {
        case EMapType.toLocation:
          break;
        case EMapType.filters:
          break;
        case EMapType.setPoints:
          setMarkers([
            ...markers,
            {
              latitude: event.data.data.lat,
              longitude: event.data.data.lng,
            },
          ]);
          setStores([]);
          break;
        case EMapType.removePoints:
          break;
        case EMapType.resetPoints:
          break;
        case EMapType.point_for_stores:
          setMarkersStore({
            latitude: event.data.data.lat,
            longitude: event.data.data.lng,
          });
          break;
        case EMapType.setPath:
          setStores([]);
          if (event?.data.data.steps) {
            onReceiveData(event?.data.data);
          }

          setPathPoints({
            startPoint: {
              latitude: event.data.data.lat,
              longitude: event.data.data.lng,
            },
            endPoint: {
              latitude: event.data.data.lat1,
              longitude: event.data.data.lng1,
            },
            showRoute: event?.data.data.steps ? false : true,
          });

          break;
        case EMapType.setStepByStep:
          break;
        case EMapType.clearMap:
          break;
        case EMapType.removePath:
          remove_shown_path();

          break;

        case EMapType.refresh:
          setRefresh(!refresh);
          setAccess_token(event.data.data.accessToken);
          break;
        case EMapType.for_runner:
          setForWho(UserType.runner);
          setRefresh(!refresh);
          setAccess_token(event.data.data.accessToken);

          break;
        case EMapType.for_admin:
          setForWho(UserType.admin);
          break;
      }
    });
  }

  const onClick = () => {
    // when implimenting onclick event disable click when forWho is store
    console.log("event");
  };

  const dataLayer: any = {
    id: "route",
    type: "line",
    source: "route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#888",
      "line-width": 8,
    },
  };

  const checkLimits = (lat: number, lng: number) => {
    if (mapConstants.limits.maxLat < lat || mapConstants.limits.minLat > lat) {
      return false;
    }
    if (mapConstants.limits.maxLng < lng || mapConstants.limits.minLng > lng) {
      return false;
    }
    return true;
  };

  const Drag = (drag: any) => {
    setViewport({
      ...viewport,
      longitude: drag?.viewState?.longitude,
      latitude: drag?.viewState?.latitude,
      zoom: drag?.viewState?.zoom,
    });
  };
  const onZoom = (zoom: any) => {
    setViewport({
      ...viewport,
      longitude: zoom?.viewState?.longitude,
      latitude: zoom?.viewState?.latitude,
    });
  };

  const getDirections = async (
    start: [number, number],
    end: [number, number],
    showRoute: boolean
  ) => {
    const API_URL = `https://api.mapbox.com/directions/v5/mapbox/walking/${start[0]},${start[1]};${end[0]},${end[1]}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&geometries=geojson&exclude=ferry&banner_instructions=true&steps=true&voice_instructions=true`;
    const response = await fetch(API_URL);
    const data = await response.json();
    if (data && data?.routes[0]?.geometry) {
      setRoute(data?.routes[0]?.geometry);
      setSteps(data?.routes[0]);

      if (showRoute) {
        onReceiveData({
          steps: {
            distance: data?.routes[0].distance,
            duration: data?.routes[0].duration,
            from: "currentLocation",
          },
        });
      }
    } else {
      console.log(JSON.stringify(data));
    }

    setViewport({
      ...viewport,
      longitude: start[0],
      latitude: start[1],
    });
    // setSteps(data.routes[0].legs[0].steps);
    // onReceiveData({ steps: data.routes[0] });
  };

  useEffect(() => {
    if (pathPoints !== null) {
      const start: [number, number] = [
        pathPoints.startPoint.longitude,
        pathPoints.startPoint.latitude,
      ];
      const end: [number, number] = [
        pathPoints.endPoint.longitude,
        pathPoints.endPoint.latitude,
      ];
      const showRoute = pathPoints.showRoute;
      getDirections(start, end, showRoute);
    }
  }, [pathPoints]);

  const showPath = (coord: any, showRoute?: boolean) => {
    setPathPoints({
      startPoint: {
        latitude: coord.lat,
        longitude: coord.lng,
      },
      endPoint: {
        latitude: coord.lat1,
        longitude: coord.lng1,
      },
      showRoute,
    });
  };

  useEffect(() => {
    if (removePath === true) {
      remove_shown_path();
    }
  }, [removePath]);

  const remove_shown_path = () => {
    setPathPoints(null);
    setRoute(null);
    setSteps(null);
    onReceiveData(null);
  };

  // uncomment below code to get current location tracker
  const geoControlRef = useRef<mapboxgl.GeolocateControl>(null);

  useEffect(() => {
    // Activate as soon as the control is loaded
    geoControlRef.current?.trigger();
  }, [geoControlRef.current]);

  function onGeolocate(event: any) {
    setViewport({
      ...viewport,
      latitude: event.coords?.latitude,
      longitude: event.coords?.longitude,
    });
  }
  useEffect(() => {
    setViewport({
      ...viewport,
      zoom: 15,
    });
    setViewport({
      ...viewport,
      zoom: 12.5,
    });
  }, [refresh]);

  return (
    <>
      <Map
        initialViewState={viewport}
        mapStyle={process.env.REACT_APP_MAP_STYLE}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onClick={onClick}
        cursor={"pointer"}
        maxBounds={[
          mapConstants.bounds.southwest as [number, number],
          mapConstants.bounds.northeast as [number, number],
        ]}
        // onViewportChange={handleViewportChange}
        longitude={
          forWho === UserType.store
            ? markersStore?.longitude
              ? markersStore.longitude
              : viewport.longitude
            : viewport.longitude
        }
        latitude={
          forWho === UserType.store
            ? markersStore?.latitude
              ? markersStore?.latitude
              : viewport.latitude
            : viewport.latitude
        }
        onDrag={Drag}
        onZoom={onZoom}
        doubleClickZoom={true}
      >
        {forWho === UserType.admin || forWho === "" ? (
          <GeocoderControl
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? ""}
            position="top-left"
            bbox={[12.243241, 45.41438, 12.414473, 45.46751]} /// limit the search to only venice
          />
        ) : (
          <div></div>
        )}
        {forWho === "" ? (
          <div>
            {stores.map((store: any, index: number) => (
              <Marker
                key={`marker-${index}`}
                longitude={store.longitude}
                latitude={store.latitude}
                anchor="bottom"
                onClick={(e) => {
                  // If we let the click event propagates to the map, it will immediately close the popup
                  // with `closeOnClick: true`
                  e.originalEvent.stopPropagation();
                  setPopupInfo(store);
                }}
              >
                <CustomPin
                  size={35}
                  imgPath={`./images/icons/${store.category}/${
                    categories[store.subcategory]
                  }.png`}
                />
              </Marker>
            ))}
            {markers.map((m: any, index: number) => (
              <Marker
                key={`marker-${index}`}
                longitude={m.longitude}
                latitude={m.latitude}
                anchor="bottom"
              >
                <Pin />
              </Marker>
            ))}
            <Source type="geojson" data={{ ...data }}>
              <Layer {...dataLayer} />
            </Source>
            <GeolocateControl
              ref={geoControlRef}
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
              showAccuracyCircle={true}
              showUserLocation={true}
              showUserHeading={true}
              onGeolocate={onGeolocate}
            />
            {popupInfo && (
              <Popup
                anchor="top"
                longitude={Number(popupInfo.longitude)}
                latitude={Number(popupInfo.latitude)}
                onClose={() => setPopupInfo(null)}
              >
                <div>{popupInfo.business_name}</div>
                <img
                  width="100%"
                  src={`https://www.cocaiexpress.com/api/uploads/stores/${popupInfo.id}/${popupInfo.img_path}`}
                />
              </Popup>
            )}
          </div>
        ) : forWho === UserType.admin ? (
          <MapAdmin
            refresh={refresh}
            stores={stores}
            markers={markers}
            popupInfo={popupInfo}
            categories={categories}
            pathPoints={pathPoints}
            route={route}
            steps={steps}
            showpath={(coordinates: any, showRoute = false) =>
              showPath(coordinates, showRoute)
            }
            onGeolocate={onGeolocate}
            access_token={access_token}
            onrecieveRunnerDetail={onrecieveRunnerDetail}
            showDirectioAdmin={showDirectioAdmin}
            onReceiveData={onReceiveData}
          ></MapAdmin>
        ) : forWho === UserType.store ? (
          <MapStore
            refresh={refresh}
            onReceiveData={onReceiveData}
            stores={stores}
            markers={markersStore}
            popupInfo={popupInfo}
            categories={categories}
            pathPoints={pathPoints}
            route={route}
            steps={steps}
            showpath={(coordinates: any, showRoute = false) =>
              showPath(coordinates, showRoute)
            }
            onGeolocate={onGeolocate}
            access_token={access_token}
          />
        ) : (
          <MapRunner
            refresh={refresh}
            onReceiveData={onReceiveData}
            stores={stores}
            markers={markers}
            popupInfo={popupInfo}
            categories={categories}
            pathPoints={pathPoints}
            route={route}
            steps={steps}
            showpath={(coordinates: any, showRoute = false) =>
              showPath(coordinates, showRoute)
            }
            onGeolocate={onGeolocate}
            access_token={access_token}
          ></MapRunner>
        )}
      </Map>
    </>
  );
};

export default ReactMap;
