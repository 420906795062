import React, { useState, useEffect } from "react";
import "./StatusIndicator.css"; // Make sure to have this CSS file created

interface WorkHourIndicatorProps {
  startTime: Date; // Assuming startTime is a Date object
  endTime: Date;
  isCurrent: boolean;
}

const WorkHourIndicator: React.FC<WorkHourIndicatorProps> = ({
  startTime,
  endTime,
  isCurrent,
}) => {
  // Initialize currentTime with the current Date object
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [animate, setAnimate] = useState<boolean>(false); //
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAnimate(true);
    }, 300);
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Cleanup on unmount
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, []);

  const totalWorkMilliseconds = endTime.getTime() - startTime.getTime();
  // Calculate time worked in milliseconds
  const timeWorkedMilliseconds = currentTime.getTime() - startTime.getTime();
  // Calculate progress
  const progressPercentage = Math.min(
    (timeWorkedMilliseconds / totalWorkMilliseconds) * 100,
    100
  );

  return (
    <div
      className={`${isCurrent ? "status-indicator" : "status-indicator-other"}`}
    >
      <div
        className="progress-bar"
        style={{ width: animate ? `${progressPercentage}%` : "0%" }}
      ></div>

      {progressPercentage < 100 ? (
        <span className={isCurrent ? "progress-text" : "progress-text-other"}>
          {`${startTime.getHours()}:${startTime
            .getMinutes()
            .toString()
            .padStart(2, "0")} - ${endTime.getHours()}:${endTime
            .getMinutes()
            .toString()
            .padStart(2, "0")}`}
        </span>
      ) : (
        <span className="progress-text">Finished</span>
      )}
    </div>
  );
};

export default WorkHourIndicator;
