export enum OrderStatusEnum {
  NUOVO = 'nuovo',
  CONFERMATO = 'confermato',
  PRENOTATO_DAL_RUNNER = 'prenotato dal runner',
  ACCETTATO_DAL_RISTORANTE = 'accettato dal ristorante',
  IN_PREPARAZIONE = 'in preparazione',
  IN_ATTESA_DEL_RISTORANTE = 'in attesa del ristorante',
  PRONTO = 'pronto',
  RITIRATO = 'ritirato',
  CONSEGNATO = 'consegnato',
  ANNULLATO = 'annullato',
  RIFIUTATO_DA_COCAI = 'rifiutato da cocai',
  RIFIUTATO_DAL_RISTORANTE = 'rifiutato dal ristorante',
  RIMBORSATO = 'rimborsato',
  SEGNALAZIONE = 'segnalazione',
}
